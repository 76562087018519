.nav{
    flex: 1;
}

ul{
    padding: 24px 40px;
    margin-top: 0px;
}

@media (max-width: 768px) {
    ul{
        padding: 24px 20px;
        border-bottom: 3px solid var(--secondary-color);
        transition: border-color 0.45s ease-in;
    }
}

@media (max-width: 375px) {
    ul{
        padding: 24px 10px;
        border-bottom: 3px solid var(--secondary-color);
        transition: border-color ease-in 0.45s;;
    }
}