body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  display: flex;
  margin: 60px;
  font-family: Helvetica;
  font-size: 24px;
  border-top: 3px solid var(--secondary-color);
  transition: border-color 0.45s ease-in;
  }

  .container h1{
    font-family: Helvetica;
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
    padding-right: 30px;
    margin: 0px;
  }

  .container a{
    text-decoration: none;
    color: inherit;
  }

  @media (max-width: 768px) {
    .container {
      margin: 30px;
      flex-direction: column;
      font-size: 24px;
    }
    .container h1{
      font-size: 24px;
    }
  }

  @media (max-width: 375px) {
    .container {
      margin: 10px;
      font-size: 20px;
    }
    .container h1{
      font-size: 20px;
    }
  }



.current-text {
  opacity: 0;
  transition: opacity 1.5s ease-in;
}

.current-text.fadein {
  opacity: 1;
}

.magic{
  padding: 12px;
  width: 45px;
  height: 45px;
  fill: var(--secondary-color);
  border: 0px var(--secondary-color) solid;
  border-radius: 12px;
  margin: 10px;
  transition: ease-in 0.2s;
}

.magic:hover{
  background-color: var(--secondary-color);
  fill: var(--primary-color);
  cursor: pointer;
  transition: 0.3s;
}