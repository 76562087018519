.fadein {
    animation: fadeInAnimation 2s forwards;
  }
  
  .fadeout {
    animation: fadeOutAnimation 2s forwards;
    animation-delay: 1s;
  }
  
  @keyframes fadeInAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOutAnimation {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .fadein-delay{
    animation: fadeInAnimation 2s forwards;
    animation-delay: 8s;
  }