.page{
    flex: 2;
    margin-block-start: 1em;
    opacity: 0;
    margin-top: 52px;

}

.page p{
  max-width: 600px;
}

.page a{
  border-bottom: 3px solid var(--secondary-color);
  transition: border-color 0.45s ease-in;
}

@media (max-width: 768px) {
    .page {
      padding: 0px  20px;
      margin-block-start: 0px;
    }
  }

  @media (max-width: 375px) {
    .page {
      padding: 0px  10px;
      margin-block-start: 0px;
    }
  }